import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import WeekDayButton from "../../components/Button/WeekDayButton";
import MeetingTimeButton from "../../components/Button/MeetingTimeButton";
import { MenuBar } from "../../components/Menu/Menu";
import { Option, Rating, Select } from "@material-tailwind/react";
import { useSelector, useDispatch } from "react-redux";
import { getReviews } from "../../store/actions/meeting.action";
import { getLeaderById, getUserData } from "../../store/actions/user.action";
// import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { toast } from "react-toastify";
import { setHours, setMinutes } from "date-fns";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import {
  transform_timezone,
  transform_languages,
  transform_skillLevel,
  transform_gender,
  // transform_timeslot
} from "../../components/Transformation/Transformations";
import QRCodeGenerator from "../../utils/qrcode";
import { MakeWishTip } from "../../utils/MakeWishTip";

function RequestMeeting(props) {
  const { id } = useParams();
  const [selectTime, setSelectTime] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectWeekDay, setSelectWeekDay] = useState("Mon");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const currentLeader = useSelector((state) => state.user.currentLeader);
  const userProfile = useSelector((state) => state.user.currentUser);
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const currentReviews = useSelector((state) => state.meeting.currentReviews);
  const review = currentReviews?.results;
  const reversedReviews = Array.isArray(review) ? [...review].reverse() : [];
  const [startDate, setStartDate] = useState(
    null || setHours(setMinutes(new Date(), 0), 9)
  );
  // const [selectedSlot, setSelectedSlot] = useState(false);
  const [currentDay, setCurrentDay] = useState(""); // eslint-disable-line no-unused-vars
  // const [currentTime, setCurrentTime] = useState();

  // const [selectedDayTime, setSelectedDayTime] = useState({ day: "", time: "" });
  const [meetingLength, setMeetingLength] = useState();
  const [meetingLengthError, setMeetingLengthError] = useState();
  // const [availabilityData, setAvailabilityData] = useState({});
  const [showReviews, setShowReviews] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [allBookedSlots, setAllBookedSlots] = useState({});
  const { t } = useTranslation();

  const user_timezone = userProfile?.timezone
    ? userProfile.timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    dispatch(getUserData({ token, mail }));
    dispatch(getLeaderById({ token, id }));
  }, [dispatch, token, id, mail]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const confirmMeeting = () => {
    if (!meetingLength) {
      setMeetingLengthError("Meeting Length is Required");
      return;
    }
    setMeetingLengthError("");
    if (!token || !mail || !userProfile) {
      toast.warn(t("generic.please_login"), {
        position: "bottom-right",
      });
      navigation("/login");
      return;
    }

    navigation(`/leaders/confirmation/${id}/`, {
      state: {
        date: startDate,
        length: meetingLength,
      },
    });
  };

  // useEffect(() => {
  //   let dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //   let newDay = new Date().getDay();

  //   setSelectWeekDay(dayArray[newDay]);

  //   setCurrentDay(newDay);

  //   // const dates = new Date();
  //   // let time = dates.toLocaleTimeString([], {
  //   //   hour: "2-digit",
  //   //   minute: "2-digit",
  //   //   hour12: false,
  //   // });
  //   // time = time?.split(" ")?.[0];
  //   // setCurrentTime(time);
  // }, []);

  useEffect(() => {
    const availabilities = currentLeader?.owner?.availabilities || [];

    const slotsByDay = dayArray.reduce((acc, day) => {
      acc[day] = [];
      return acc;
    }, {});

    availabilities.forEach((slot) => {
      const now = moment().tz(slot.timezone);
      const localTime = now
        .clone()
        .day(slot.day)
        .hour(slot.hour)
        .minute(slot.minute)
        .second(0)
        .tz(user_timezone);
      if (localTime.isBefore(now)) {
        // If it's in the past, adjust the date to the next occurrence
        localTime.add(7, "days");
      }
      const formattedTime = localTime.format("HH:mm");
      const dayShortName = localTime.format("ddd"); // Short name of the day
      slotsByDay[dayShortName].push(formattedTime);
    });

    setAllBookedSlots(slotsByDay);

    const bookedSlots = availabilities.map((slot) => {
      const now = moment().tz(slot.timezone);
      const localTime = now
        .clone()
        .day(slot.day)
        .hour(slot.hour)
        .minute(slot.minute)
        .second(0)
        .tz(user_timezone);
      if (localTime.isBefore(now)) {
        // If it's in the past, adjust the date to the next occurrence
        localTime.add(7, "days");
      }
      const formattedTime = localTime.format("HH:mm");
      const dayShortName = localTime.format("ddd");
      return {
        day: dayShortName,
        time: formattedTime,
      };
    });

    setSelectedSlots(bookedSlots);

    let newDay = new Date().getDay();
    setCurrentDay(newDay === 0 ? 7 : newDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLeader?.owner?.availabilities, currentLeader?.owner?.timezone]);

  const dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const filterPastDates = (date) => moment(date).isAfter(moment(), "day");
  const filterPassedTime = (time) => moment(time).isAfter(moment());

  const handleSlotToggle = (day, time) => {
    const currentDay = moment().startOf("day");
    const selectedDayIndex = dayArray.indexOf(day);
    const currentDayIndex = currentDay.day();

    // Calculate days until the next occurrence of the selected day
    const daysUntilNext = (selectedDayIndex - currentDayIndex + 7) % 7 || 7;

    const selectedDate = currentDay
      .add(daysUntilNext, "days")
      .format("MMMM D, YYYY");

    const fullDateTime = moment(
      `${selectedDate} ${time}`,
      "MMMM D, YYYY hh:mm A"
    ).toDate();

    setSelectedSlots({ day, time, fullDateTime });
    setStartDate(fullDateTime);
  };

  // setSelectedSlots((prev) => {
  //   const currentDaySlots = prev[day] || [];
  //   if (currentDaySlots.includes(time)) {
  //     // Deselect slot
  //     return {
  //       ...prev,
  //       [day]: currentDaySlots.filter((slot) => slot !== time)
  //     };
  //   } else {
  //     // Select slot
  //     return {
  //       ...prev,
  //       [day]: [...currentDaySlots, time]
  //     };
  //   }
  // });

  // const filterPastDates = (date) => {
  //   const currentDate = moment().startOf("day");
  //   const selectedDate = moment(date).startOf("day");
  //   return selectedDate.isSameOrAfter(currentDate);
  // };

  // const filterPassedTime = (time) => {
  //   const currentTime = moment();
  //   const selectedTime = moment(time);
  //   return selectedTime.isAfter(currentTime);
  // };

  // const formattedAvailability = useMemo(() => {
  //   const availabilities = currentLeader?.owner?.availabilities;
  //   const availability = {};

  //   availabilities?.forEach((slot) => {
  //     let [zonedDay, formattedDate] = transform_timeslot(slot, user_timezone);

  //     if (!availability[zonedDay]) {
  //       availability[zonedDay] = [];
  //     }
  //     let note = slot.note;
  //     availability[zonedDay].push({ time: formattedDate, note });
  //   });
  //   return availability;
  // }, [currentLeader?.owner?.availabilities, user_timezone]);

  // useEffect(() => {
  //   if (formattedAvailability) {
  //     setAvailabilityData(formattedAvailability);
  //   }
  // }, [formattedAvailability]);
  // const getDayNumber = (day) => {
  //   const days = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  //   return days[day];
  // };

  // const handleDayTimeSelect = (day, time) => {
  //   setSelectedDayTime({ day, time });

  //   // Get the numeric day index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  //   const dayNumber = getDayNumber(day);
  //   const [hours, minutes] = time?.split(":").map(Number);

  //   // Create a new Date object for the selected day and time
  //   const newDate = new Date();
  //   const currentDay = newDate.getDay();
  //   const daysUntilSelected = (dayNumber - currentDay + 7) % 7;
  //   newDate.setDate(newDate.getDate() + daysUntilSelected);
  //   newDate.setHours(hours, minutes, 0, 0);

  //   // Convert to UTC
  //   const utcDate = new Date(newDate.toUTCString());
  //   setStartDate(utcDate);
  // };

  // const convertToUTC = (date) => {
  //   return moment(date).utc().toDate(); // Convert local time to UTC
  // };

  // const TimeSlotsList = ({
  //   day,
  //   data,
  //   selectedDayTime,
  //   onDayTimeSelect,
  //   notes
  // }) => {
  //   const isSelected =
  //     selectedDayTime?.day === day && selectedDayTime?.time === data;

  //   return (
  //     <div
  //       className={`slotCard border ${
  //         isSelected
  //           ? "border-gray-500 bg-[#0042259e] border-2"
  //           : "border-transparent bg-white border-2"
  //       } rounded-sm p-2 cursor-pointer group relative`}
  //       onClick={() => onDayTimeSelect(day, data)}
  //     >
  //       <div
  //         className={`relative flex items-center justify-between gap-4 text-gray-700 shadow-none`}
  //       >
  //         <h5
  //           className={`text-xl font-semibold leading-snug tracking-normal font-sans ${
  //             isSelected ? "text-black" : "text-gray-700"
  //           }`}
  //         >
  //           {data}
  //         </h5>
  //         <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden w-48 p-2 bg-gray-600 text-white font-medium text-sm rounded shadow-lg group-hover:block">
  //           {notes || "No notes available"}
  //           <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-600 rotate-45"></div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const handleToggleReviews = () => {
    let lid = currentLeader?.owner?.id;
    dispatch(getReviews({ token, lid }));
    setShowReviews(!showReviews); // Toggle the visibility state
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 xl:p-0">
      <Header openMenu={openMenuBar} />
      {!currentLeader && (
        <div className="min-h-96 flex items-center justify-center border-t border-blue-gray-50 p-4">
          <Loader />
        </div>
      )}
      {currentLeader && (
        <>
          <div className="flex justify-center">
            <span className="font-semibold text-3xl md:text-4xl xl:text-5xl overline decoration-green-950">
              {t("leader_profile.I")}
            </span>
            <span className="font-semibold text-3xl md:text-4xl xl:text-5xl">
              {t("leader_profile.title")}
            </span>
          </div>
          <div className="grid grid-cols-1 m-4 md:m-8 gap-8">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
              <div className="flex flex-col items-center mt-20">
                <img
                  src={
                    currentLeader?.owner?.image
                      ? `${currentLeader?.owner?.image}`
                      : "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
                  }
                  alt="UserImage"
                  className="rounded-full border-solid border-[10px] border-[#004225] w-[250px] h-[250px] md:w-[350px] md:h-[350px]"
                />
                <div className="text-3xl font-semibold mt-8">
                  {currentLeader.owner.first_name +
                    " " +
                    currentLeader.owner.last_name}
                </div>
                <div className="text-xl font-normal">
                  {t("leader_profile.teaching")}{" "}
                  {t(transform_languages(currentLeader?.teaching_language))}
                </div>
              </div>

              <div className="flex flex-col gap-4 w-full mt-20 items-center xl:items-start">
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.age")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {currentLeader?.owner?.age}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.first_language")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(
                      transform_languages(currentLeader?.owner?.first_language)
                    )}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.first_language_skill")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(
                      transform_skillLevel(
                        currentLeader?.owner?.first_language_skill_level
                      )
                    )}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.second_language")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(
                      transform_skillLevel(
                        currentLeader?.owner?.second_language
                      )
                    )}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.teaching_language")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(transform_languages(currentLeader?.teaching_language))}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.training_language_skill")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(
                      transform_skillLevel(
                        currentLeader?.teaching_language_skill_level
                      )
                    )}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.introduction")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {currentLeader?.introduction}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.interested_topic")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {currentLeader?.interested_topics}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.school")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {currentLeader?.owner?.school}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("user_home.city_country")}:
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {currentLeader?.owner?.city_country}
                  </span>
                </div>
                <div>
                  <span className="text-[20px] md:text-3xl font-medium">
                    {t("leader_profile.sex")}
                  </span>
                  <span className="text-lg md:text-2xl font-normal pl-2">
                    {t(transform_gender(currentLeader?.owner?.sex))}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "0px" }}>
            <div style={{ margin: "0" }}>{t("leader_profile.share")}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "0",
              }}
            >
              <QRCodeGenerator url={window.location.href} width={200} />
            </div>
          </div>

          <div className="2xl:col-span-2 flex flex-col gap-4 2xl:gap-12 mt-16 justify-center items-center">
            <div
              onClick={handleToggleReviews}
              className="cursor-pointer text-xl underline font-bold"
            >
              {showReviews
                ? t("leader_profile.hide_review")
                : t("leader_profile.recent_review")}
            </div>
            {showReviews && (
              <div className="2xl:col-span-2 flex flex-col gap-4 2xl:gap-12 mt-0 justify-center items-center">
                <div className="flex flex-col gap-3">
                  {reversedReviews.slice(0, 10).map((review, index) => {
                    return (
                      <div key={index} className="flex flex-col md:flex-row">
                        <div className="flex justify-center items-center">
                          <p className="text-xl font-bold">
                            {t("leader_profile.review_from")}
                          </p>
                          <p className="ml-2 mr-8 text-xl">
                            {review.meeting_id?.follower?.first_name +
                              " " +
                              review.meeting_id?.follower?.last_name}
                          </p>
                          <Rating value={review?.stars} readonly />
                        </div>
                        <div className="flex flex-col mt-2 md:mt-0 md:flex-row items-center justify-center">
                          <p className="text-xl font-bold md:ml-8">
                            {t("leader_profile.review_to")}
                          </p>
                          <p className="md:ml-2 text-xl">{review?.comment}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center text-2xl md:text-3xl mt-16 font-bold">
            {t("leader_profile.availability_title")}
          </div>
          <span className="flex justify-center mt-2 font-medium text-lg">
            {t("meeting.table_text")}{" "}
            <span style={{ color: "red" }}>
              {" "}
              {t(transform_timezone(user_timezone))}{" "}
            </span>
          </span>
          <div className=" flex flex-col gap-8 md:gap-5 md:mt-8 justify-center">
            <div className="overflow-scroll md:overflow-auto">
              <div className="grid grid-cols-7 p-4 xl:px-[220px] lg:px-28 w-[800px] md:w-full">
                {dayArray.map((day) => (
                  <div key={day} className="flex flex-col items-center">
                    <WeekDayButton name={day} />
                    <div className="space-y-2 mt-3">
                      {allBookedSlots[day]?.length > 0 ? (
                        allBookedSlots[day].map((time, index) => (
                          <div
                            key={index}
                            onClick={() => handleSlotToggle(day, time)}
                            className={`text-center px-4 py-2 border rounded font-semibold text-lg cursor-pointer ${
                              selectedSlots?.day === day &&
                              selectedSlots?.time === time
                                ? "bg-[#004225] text-white"
                                : "bg-white text-gray-700 border-gray-300"
                            }`}
                          >
                            {time}
                          </div>
                        ))
                      ) : (
                        <div className="text-gray-700 text-md font-semibold">
                          No slots
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-4 md:gap-12 items-center datepicker-refre">
              <span className="flex justify-center mt-2 font-medium text-lg">
                {t("leader_profile.choose_your_time")}
              </span>

              <div className="wrapper_picker_meetLength flex flex-col sm:flex-row justify-between items-center rounded-md sm:w-[640px]">
                <div className="dat-picker-wrapper flex items-center bg-white rounded-md w-[400px] relative pl-12">
                  {" "}
                  {/* Added left padding for icon space */}
                  <button
                    className="icon_wrapper absolute left-3 flex items-center justify-center" // Adjusted positioning
                    onClick={() =>
                      document
                        .querySelector(".dat-picker-wrapper input")
                        .focus()
                    }
                  >
                    <MdDateRange size={24} />
                  </button>
                  <DatePicker
                    className="w-[240px] ml-1"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    // onChange={(date) => {
                    //   const utcDate = convertToUTC(date);
                    //   setStartDate(utcDate);
                    //   let dayArray = [
                    //     "Sun",
                    //     "Mon",
                    //     "Tue",
                    //     "Wed",
                    //     "Thu",
                    //     "Fri",
                    //     "Sat"
                    //   ];
                    //   let newDay = moment(utcDate).utc().day();
                    //   setSelectWeekDay(dayArray[newDay]);
                    // }}
                    showTimeSelect
                    filterTime={filterPassedTime}
                    filterDate={filterPastDates}
                    dateFormat="MMMM d, yyyy H:mm aa"
                    timeFormat="HH:mm"
                  />
                  <div
                    className="w-[180px] flex justify-center"
                    style={{ color: "red" }}
                  >
                    {t(transform_timezone(user_timezone))}
                  </div>
                </div>

                <div className="select_meeting_length ml-8 bg-white p-2 rounded-md">
                  <Select
                    label={t("leader_profile.choose_length")}
                    onChange={(e) => setMeetingLength(e)}
                  >
                    <Option value="15">15</Option>
                    <Option value="30">30</Option>
                    <Option value="45">45</Option>
                    <Option value="60">60</Option>
                  </Select>
                </div>
              </div>

              {/* {!selectedSlot && (
              <h6 style={{ color: "red" }}>
                Selected time is not available. Please choose another slot.
              </h6>
            )} */}

              <div className="flex flex-wrap justify-center gap-4">
                {currentLeader.availability_time &&
                  currentLeader.availability_time.map((meetingTime) => {
                    return meetingTime.day === selectWeekDay ? (
                      <MeetingTimeButton
                        key={meetingTime.id}
                        nowState={selectTime}
                        time={meetingTime.time}
                        onClick={() => setSelectTime(meetingTime.time)}
                      />
                    ) : null;
                  })}
              </div>
            </div>
          </div>
          {meetingLengthError && (
            <h6 className="text-center text-red-400">{meetingLengthError}.</h6>
          )}
          <div className="flex justify-center mt-20 mb-20 md:mt-10">
            <button
              className="h-[50px] w-[220px] md:h-[80px] md:w-[350px] bg-[#004225] rounded-[20px] md:rounded-[40px] text-2xl md:text3xl xl:text-4xl font-bold text-[#F5F5F5]"
              onClick={confirmMeeting}
            >
              {t("leader_profile.request_meeting_button")}
            </button>
          </div>
          <MakeWishTip />
        </>
      )}
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}

export default RequestMeeting;
