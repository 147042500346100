import React, { useState } from "react";
import { registerWish } from "../../store/actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@material-tailwind/react";
import { MenuBar } from "../../components/Menu/Menu";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { languages } from "../../components/Transformation/Transformations"

const Wishes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const [language, setLanguage] = useState("");
  const [day, setDay] = useState("");
  const [note, setNote] = useState("");
  const [interestedTopics, setInterestedTopics] = useState("");
  const [meetingLength, setMeetingLength] = useState("");
  const [time, setTime] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);

  const handleSubmit = (e) => {
    const [hour, minute] = time.split(":");
    if (!token) {
      toast.warn(t("generic.please_login"), {position: "bottom-right",});
      return;
    }
    if (!language || !day || !hour || !minute || !meetingLength) {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }
    dispatch(
      registerWish({
        language,
        day,
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        timezone: currentUser?.timezone,
        meetingLength: parseInt(meetingLength, 10),
        interestedTopics,
        note,
        token,
        mail,
        owner: currentUser?.results?.[0]?.id,
      })
    );
    setLanguage("");
    setDay("");
    setInterestedTopics("");
    setMeetingLength("");
    setNote("");
    setTime("");
    navigate("/schedule-meeting");
  };
  const openMenuBar = () => {
    setMenuOpen(true);
  };
  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  return (
    <div className="max-w-screen-2xl mx-auto px-4">
      <Header openMenu={openMenuBar} />
      <div className="flex justify-center ">
        <p className="leading-relaxed font-medium text-[18px] text-pink-900 italic w-fit py-1 px-5 border-l-4 border-r-4 border-x-[#F07D7B] bg-[#5376d633] rounded-lg shadow-lg">
           {t("wish.title")}
        </p>
      </div>
      <div className="w-full flex md:flex-nowrap lg:gap-10 gap-5 flex-wrap items-center my-50 rounded overflow-hidden">
        <div className="md:w-1/2 w-full">
          <img className="w-full" src={"https://res.cloudinary.com/do6t8sxgo/image/upload/v1724620287/Wish_tc7n4x.png"} alt="WishImage" />
        </div>
        <div className="md:w-1/2 w-full">
          <div className="my-5 mx-auto max-w-[40rem] p-6 bg-white shadow-md rounded-tl-[56px] rounded-br-[56px] border-y-[3px] border-[#328335]">
            <h2 className="text-2xl font-semibold mb-6">{t("wish.form")}</h2>
            <form>
              <div className="mb-4">
                <label
                  htmlFor="language"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.lang")}
                </label>
                <select
                  required
                  id="language"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                  value={language}
                >
                  <option value="">{t("wish.choose")}</option>
                  {languages.map((language) => (
                    <option key={language.value} value={language.value}>
                      {t(language.label)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dayOfWeek"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.week_day")}
                </label>
                <select
                  required
                  id="dayOfWeek"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    setDay(e.target.value);
                  }}
                  value={day}
                >
                  <option value="">{t("wish.choose_week_day")}</option>
                  <option value="Mon">{t("generic.mon")}</option>
                  <option value="Tue">{t("generic.tue")}</option>
                  <option value="Wed">{t("generic.wed")}</option>
                  <option value="Thu">{t("generic.thu")}</option>
                  <option value="Fri">{t("generic.fri")}</option>
                  <option value="Sat">{t("generic.sat")}</option>
                  <option value="Sun">{t("generic.sun")}</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="meetingTime"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.time")}
                </label>
                <input
                  type="time"
                  id="meetingTime"
                  value={time}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:[#004350]"
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="meetingTime"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.length")}
                </label>
                <select
                  id="meetingLength"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={meetingLength}
                  onChange={(e) => {
                    setMeetingLength(e.target.value);
                  }}
                >
                  <option value="">{t("wish.choose_length")}</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="60">60</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="interestedTopic"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.topic")}
                </label>
                <input
                  type="text"
                  id="interestedTopic"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:[#004350]"
                  placeholder={t("wish.choose_topic")}
                  value={interestedTopics}
                  onChange={(e) => {
                    setInterestedTopics(e.target.value);
                  }}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="note"
                  className="block text-gray-800 font-medium mb-2"
                >
                  {t("wish.note")}
                </label>
                <textarea
                  id="note"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:[#004350]"
                  placeholder={t("wish.input_note")}
                  rows="4"
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="text-right">
                <Button
                  variant="gradient"
                  color="green"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <span>{t("wish.submit")}</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
};

export default Wishes;
