import moment from "moment-timezone";

export function transform_meeting_provider(p) {
  switch (p) {
    case "ZOOM":
      return "user_home.zoom";
    case "VooV":
      return "user_home.voov";
    default:
      return p;
  }
}

export function transform_timezone(zone) {
  switch (zone) {
    case "US/Pacific":
      return "generic.timezone_pacific";
    case "US/Eastern":
      return "generic.timezone_eastern";
    case "US/Mountain":
      return "generic.timezone_mountain";
    case "US/Central":
      return "generic.timezone_central";
    case "PRC":
      return "generic.timezone_prc";
    default:
      return zone;
  }
}

export function transform_languages(language) {
  switch (language) {
    case "EN":
      return "generic.english";
    case "CN":
      return "generic.chinese";
    case "ES":
      return "generic.spanish";
    case "FR":
      return "generic.french";
    default:
      return language;
  }
}

export function transform_gender(sex) {
  switch (sex) {
    case "M":
      return "generic.male";
    case "F":
      return "generic.female";
    case "U":
      return "generic.unknown_gender";
    case "O":
      return "generic.other_gender";
    default:
      return "";
  }
}

export function transform_skillLevel(skill) {
  switch (skill) {
    case 1:
      return "generic.skill_1";
    case 2:
      return "generic.skill_2";
    case 3:
      return "generic.skill_3";
    case 4:
      return "generic.skill_4";
    case 5:
      return "generic.skill_5";
    case 6:
      return "generic.skill_any";
    default:
      return "";
  }
}

export function transform_status(status) {
  switch (status) {
    case "COMPLETED":
      return "generic.completed";
    case "WAITING_FOR_REVIEW":
      return "generic.waiting_for_review";
    case "CANCELLED":
      return "generic.cancelled";
    case "REJECTED":
      return "generic.rejected";
    case "CONFIRMED":
      return "generic.confirmed";
    case "PROPOSED":
      return "generic.proposed";
    case "TEACHER_NO_SHOW":
      return "generic.teacher_no_show";
    default:
      return "";
  }
}

export function transform_payment_action(action) {
  switch (action) {
    case "BUY":
      return "payment.BUY";
    case "LEARN":
      return "payment.LEARN";
    case "TEACH":
      return "payment.TEACH";
    case "CASH_OUT":
      return "payment.CASH_OUT";
    case "DONATE":
      return "payment.ONATE";
    case "VOLUNTEER":
      return "payment.VOLUNTEER";
    case "ADJ_ADMIN":
      return "payment.ADJ_ADMIN";
    case "MEETING_CANCELLED":
      return "payment.MEETING_CANCELLED";
    default:
      return "";
  }
}

export function getStatusColor(status) {
  switch (status) {
    case "COMPLETED":
      return "green";
    case "WAITING_FOR_REVIEW":
      return "green";
    case "REJECTED":
    case "CANCELLED":
      return "red";
    case "CONFIRMED":
      return "Sienna";
    case "PROPOSED":
      return "#FFBC03";
    default:
      return "blue-gray";
  }
}

export function getStatusName(status) {
  switch (status) {
    case "COMPLETED":
      return "Completed";
    case "WAITING_FOR_REVIEW":
      return "Waiting for review";
    case "CANCELLED":
      return "Cancelled";
    case "REJECTED":
      return "Rejected";
    case "CONFIRMED":
      return "Confirmed";
    case "PROPOSED":
      return "Proposed";
    default:
      return "";
  }
}

export function transform_timeslot(slot, user_timezone) {
  const day = slot.day;
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const dayNumber = daysOfWeek.indexOf(day);
  // Calculate the start of the week (Monday) in UTC
  const startOfWeek = moment.tz(slot.timezone).startOf("isoWeek"); // isoWeek starts on Monday
  // Calculate the UTC date and time for the slot
  const zonedDate = startOfWeek
    .clone()
    .add(dayNumber, "days")
    .hours(slot.hour)
    .minutes(slot.minute);

  // Convert to the selected time zone, if provided, otherwise use UTC
  const zonedDate_viewer = zonedDate.clone().tz(user_timezone);

  // Extract the day of the week in the selected time zone
  const zonedDay = zonedDate_viewer?.format("ddd");

  // Format the date to include hours and minutes
  const formattedDate = zonedDate_viewer?.format("HH:mm");
  return [zonedDay, formattedDate];
}

export function choose_image(name) {
  switch (name) {
    case "home.title":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867201/HomeImage_ss_icfql2.jpg";
    case "home.intro":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867621/intro_s_kuk4fi.jpg";
    case "home.payment":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867622/payment_s_u0u8lz.png";
    case "home.learner":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867621/lingo_learner_s_ajywxg.png";
    case "home.teacher":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867621/lingo_teacher_s_oowdel.png";
    case "home.leana":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724866425/leana_mvs3ev.png";
    case "home.rose":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724866425/rose_lergfn.png";
    case "home.mark":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724866425/mark_i63qqi.png";
    case "intro.title":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724867786/intro.title_s_hzkzqb.jpg";
    case "leader.step1":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619320/Profile_ss_mqldgx.png";
    case "leader.step3":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724868222/Leader.step3_s_m4pyje.jpg";
    case "leader.title":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724868223/Leader.title_s_ijwdw7.jpg";
    case "leader.step2":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619319/Meeting_ss_ygifbl.png";
    case "leader.step4":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619445/completed_ss_mw4rw0.png";
    case "leader.award":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724868219/Leader.award_s_ziebdi.jpg";
    case "learner.title":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619658/learner_iqggcl.jpg";
    case "learner.step1":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619979/Search_dj84s9.svg";
    case "learner.step2":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619977/MeetingCal_zs1uz1.svg";
    case "learner.step3":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619981/Step-6_phwfpu.svg";
    case "learner.step4":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724619978/review_fhccht.svg";
    case "ourteam.harly":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1727125686/harly_pakqhb.png";
    case "ourteam.braydon":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1733163582/image_otqjzg.png";
    case "ourteam.tom":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1733163811/IMG_0936_jkmyz2.jpg";
    case "ourteam.sophie":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1734222202/sophie_lu_kvmwmy.png";
    case "ourteam.ethan":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1738113170/ethan_tian_oivghk.png";
    case "generic.nobody":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg";
    case "generic.small_logo":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1727716828/logo_lw0v51.png";
    case "leader.my_avail":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1728520176/Screenshot_2024-10-09_at_2.51.13_PM_cfuwwp.png";
    case "leader.my_photo":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1728520176/Screenshot_2024-10-09_at_2.50.38_PM_qagm6e.png";
    case "leader.my_course":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1728520176/Screenshot_2024-10-09_at_2.51.06_PM_wicnv6.png";
    case "leader.my_info":
      return "https://res.cloudinary.com/do6t8sxgo/image/upload/v1728520176/Screenshot_2024-10-09_at_2.50.59_PM_kh7bxv.png";
    default:
      return "";
  }
}


export const languages = [
  { value: "EN", label: "generic.english" },
  { value: "ES", label: "generic.spanish" },
  { value: "CN", label: "generic.chinese" },
  { value: "FR", label: "generic.french" },
];

export const courses = [
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  ...languages, // Correct way to combine arrays
];

export const base_url =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://lingoclub.org"
    : //: "https://lingoclub-2baf7ac087b1.herokuapp.com";
      "http://localhost:8000";

export const stripe_publishable_key =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY === "production"
    ? "pk_live_51PVJiJRpkyj60dzGmwKQepmQs4SB3ZWzSOVoJVqMTKpDH8GPm9RfInNfwQ2X9QjinVY4OrU1WTg6o7gsARNLdvsL00w6kK4UaX"
    : "pk_test_51PVJiJRpkyj60dzGxn1vsqlAUVyqunslqGi6gpsmt9jz0JxyisMUbolLPbWV0hOJKyVxrNYgz0u9t9y4jgUWtxg000xxs9wHyG";
