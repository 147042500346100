import React, { useState } from "react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TeamUserCard from "../../components/Cards/TeamUserCard";
import { MenuBar } from "../../components/Menu/Menu";
import { choose_image } from "../../components/Transformation/Transformations"
import { useTranslation } from "react-i18next";


function OurTeam() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };
  return (
    <div className="max-w-screen-2xl mx-auto px-4">
      <Header openMenu={openMenuBar} />
      <div>
        <div className="text-6xl font-bold ml-12 text-[#004225] z-10">
          {t("team.our_team")}
        </div>
        <div className="text-3xl font-normal mt-4 ml-12 z-10">
          {t("team.leaders")}
        </div>
        <div className="flex flex-col items-center relative">
          <div className="flex flex-col xl:flex-row justify-center gap-10 mt-24 mb-10 ml-10 z-10">
            <TeamUserCard
              name={"Harly Liu"}
              image={choose_image("ourteam.harly")}
              job={"Founder"}
              summary={t("team.harly_statement")}
            />
            <TeamUserCard
              name={"Braydon Lu"}
              image={choose_image("ourteam.braydon")}
              job={"Co-Founder"}
              summary={t("team.braydon_statement")}
            />
            <TeamUserCard
              name={"Tom Wang"}
              image={choose_image("ourteam.tom")}
              job={"Co-Founder"}
              summary={t("team.tom_statement")}
            />
          </div>
        </div>
      </div>
    <div>
        <div className="flex flex-col items-center relative">
          <div className="flex flex-col xl:flex-row justify-center gap-10 mt-24 mb-10 ml-10 z-10">
            <TeamUserCard
              name={"Sophie Lu"}
              image={choose_image("ourteam.sophie")}
              job={"Director of Marketing"}
              summary={t("team.sophie_statement")}
            />
            <TeamUserCard
              name={"Ethan Tian"}
              image={choose_image("ourteam.ethan")}
              job={"Director of STEM"}
              summary={t("team.ethan_statement")}
            />
          </div>
        </div>
    </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}

export default OurTeam;
